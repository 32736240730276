import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { injectLocalize } from "../../util/localization";
import { get } from "../../util/server";
import { ErrorInfo } from "../shared";

// User has visited /r/[fake-slug]/[id]/web so we need to forward user to new
// path using the real slug, i.e. /[real-slug]/web.

export default function OldRoute() {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const check = async () => {
      const response = await get(`restaurants/${params?.id}`);
      const slug = response?.data?.slug;
      if (slug) {
        navigate(`/${slug}/web`);
      } else {
        setError(true);
      }
    };
    check();
  }, [navigate, params?.id]);

  if (error) {
    return <ErrorInfo info={injectLocalize("oldRoute.error", params?.id)} />;
  }

  return null;
}
