import { Text } from "../../../../shared";
import styles from "./foodItem.module.css";

export default function FoodItem({ description, name, price }) {
  return (
    <div className={styles.container}>
      <Text className={styles.name}>
        <Text element="span" type="h4">
          {name}
        </Text>{" "}
        {description && (
          <Text
            className={styles.description}
            color="lightgrey"
            element="span"
            type="meta"
          >
            {description}
          </Text>
        )}
      </Text>
      <Text className={styles.price} color="orange" type="h4">
        {price} kr
      </Text>
    </div>
  );
}
