import { NotificationContainer } from "react-notifications";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AppContextProvider } from "../../context/AppContext";
import BookingModal from "../BookingModal/BookingModal";
import DomainMapping from "../DomainMapping/DomainMapping";
import OldRoute from "../OldRoute/OldRoute";
import { PageNotFound } from "../PageNotFound/PageNotFound";
import ParamsMapping from "../ParamsMapping/ParamsMapping";

export default function Root() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<DomainMapping />} path="/" />
          <Route element={<ParamsMapping />} path="restaurang/:slug/web" />
          <Route element={<OldRoute />} path="r/:slug/:id/web" />
          <Route element={<PageNotFound />} path="*" />
        </Routes>
        <NotificationContainer />
        <BookingModal />
      </BrowserRouter>
    </AppContextProvider>
  );
}
