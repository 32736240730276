import AnimatedBg from "@bulletlogic/react-animated-bg";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../context/AppContext";
import { useWindowSize } from "../../../hooks/windowSize";
import { getImageUrl } from "../../../util/image";
import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./hero.module.css";

export default function Hero({ hash, images, logo, scrolled }) {
  const appContext = useContext(AppContext);
  const { isMobile } = useWindowSize();
  const [items, setItems] = useState([]);

  const onBookClick = () => {
    appContext.onShowWidget(hash);
  };

  useEffect(() => {
    const items = images?.map((item) => {
      const img = getImageUrl(item, 1000, 1000, "thumb");
      return `url('${img}')`;
    });
    setItems(items);
  }, [images]);

  useEffect(() => {
    // Preload all images once URL has been generated.
    items?.forEach((item) => {
      const img = new window.Image();
      img.src = item;
    });
  }, [items]);

  return (
    <AnimatedBg
      className={styles.container}
      colors={items}
      delay={9}
      duration={3}
      randomMode={true}
    >
      {logo && (
        <img
          className={classNames([styles.logo, scrolled && styles.hide])}
          src={getImageUrl(logo, 800, 800, "fit")}
        />
      )}
      {isMobile && (
        <div className={styles.button} onClick={onBookClick}>
          <Text color="white" type="label">
            {localize("restaurantPage.hero.book")}
          </Text>
        </div>
      )}
    </AnimatedBg>
  );
}
