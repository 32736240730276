import { useLocation } from "react-router-dom";

import { injectLocalize } from "../../util/localization";
import { ErrorInfo } from "../shared";

export function PageNotFound() {
  const location = useLocation();
  return (
    <ErrorInfo
      info={injectLocalize("pageNotFound.error", location?.pathname)}
    />
  );
}
