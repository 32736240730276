import stringInject from "stringinject";
import { getIn } from "timm";

import sv from "../languages/sv";

const availableLanguages = { sv };

export const getLanguage = () => {
  // In future fetch language from e.g. localStorage when we support multiple.
  const language = "sv";
  return Object.keys(availableLanguages).includes(language) ? language : "sv";
};

export const localize = (key) => {
  const language = getLanguage();
  const label = getIn(availableLanguages, [language, key]);
  return label || key;
};

export const injectLocalize = (key, arr) => {
  const data = arr instanceof Array ? arr : [arr];
  return stringInject(localize(key), data);
};
