import classNames from "classnames";
import PropTypes from "prop-types";
import { createElement } from "react";

import COLORS from "../../../constants/colors";
import styles from "./text.module.css";

export default function Text({
  children,
  className,
  color,
  element,
  html,
  numberOfLines,
  type,
}) {
  const css = classNames({
    [styles.container]: true,
    [styles.body]: type === "body",
    [styles.h1]: type === "h1",
    [styles.h2]: type === "h2",
    [styles.h3]: type === "h3",
    [styles.h4]: type === "h4",
    [styles.label]: type === "label",
    [styles.meta]: type === "meta",
    [styles.truncate]: numberOfLines >= 1,
    [className]: true,
  });
  const style = {
    WebkitLineClamp: numberOfLines,
    color: COLORS[color?.toLowerCase()],
    wordBreak: numberOfLines === 1 ? "break-all" : undefined,
  };
  const dangerouslySetInnerHTML = html ? { __html: html } : undefined;
  return createElement(
    element,
    { className: css, dangerouslySetInnerHTML, style },
    html ? undefined : children
  );
}

Text.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  color: PropTypes.oneOf([
    "beige",
    "black",
    "grey",
    "lightgrey",
    "orange",
    "white",
  ]),
  element: PropTypes.oneOf(["div", "h1", "h2", "h3", "h4", "span"]),
  html: PropTypes.string,
  numberOfLines: PropTypes.number,
  type: PropTypes.oneOf(["body", "h1", "h2", "h3", "h4", "label", "meta"]),
};

Text.defaultProps = {
  children: null,
  className: null,
  color: "black",
  element: "div",
  html: null,
  numberOfLines: null,
  type: "body",
};
