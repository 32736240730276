import { format } from "format-phone-swedish";
import { useEffect, useMemo, useState } from "react";
import { replaceAt } from "timm";

import { getWeekday } from "../../../util/date";
import { injectLocalize, localize } from "../../../util/localization";
import { getMapUrl } from "../../../util/map";
import { compareObject } from "../../../util/object";
import { Text } from "../../shared";
import styles from "./contact.module.css";

export default function Contact({
  address,
  city,
  email,
  lat,
  lng,
  name,
  openingHours,
  phone,
}) {
  const [mapUrl, setMapUrl] = useState(null);

  useEffect(() => {
    if (lat && lng) {
      const url = getMapUrl({ height: 600, lat, lng, width: 600, zoom: 15 });
      setMapUrl(url);
    }
  }, [lat, lng]);

  const groupedOpeningHours = useMemo(() => {
    return openingHours
      ?.sort((a, b) => a.day - b.day)
      ?.reduce((arr, item) => {
        const lastItem = arr.slice(-1)[0];
        if (lastItem && compareObject(lastItem?.times, item?.times)) {
          // Same opening hours as last item, just update stop day.
          const updatedItem = {
            ...lastItem,
            stopDay: item.day,
          };
          arr = replaceAt(arr, arr?.length - 1, updatedItem);
        } else {
          // Otherwise add new item to the list.
          const newItem = {
            startDay: item.day,
            stopDay: item.day,
            times: item.times,
          };
          arr.push(newItem);
        }
        return arr;
      }, []);
  }, [openingHours]);

  return (
    <div className={styles.container} id="kontakt">
      <Text className={styles.subtitle} color="orange" type="label">
        {name}
      </Text>
      <Text className={styles.title} type="h1">
        {localize("restaurantPage.contact.title")}
      </Text>
      <div className={styles.content}>
        <div className={styles.contactInfo}>
          {(address || city) && (
            <div className={styles.info}>
              <Text className={styles.label} type="label">
                {localize("restaurantPage.contact.address")}
              </Text>
              {address && <Text className={styles.row}>{address}</Text>}
              {city && <Text className={styles.row}>{city}</Text>}
            </div>
          )}
          {openingHours?.length > 0 && (
            <div className={styles.info}>
              <Text className={styles.label} type="label">
                {localize("restaurantPage.contact.openingHours")}
              </Text>
              {groupedOpeningHours?.map((item) => (
                <div className={styles.day} key={item?.startDay}>
                  <Text>
                    {item?.stopDay !== item?.startDay
                      ? `${getWeekday(item?.startDay)} - ${getWeekday(
                          item?.stopDay
                        )}`
                      : getWeekday(item?.startDay)}
                  </Text>
                  <div className={styles.separator}></div>
                  <div>
                    {item?.times?.length > 0 ? (
                      item?.times?.map((time, key) => (
                        <Text key={key}>
                          {time?.open && time?.close
                            ? `${time?.open} - ${time?.close}`
                            : time?.open
                            ? `${time?.open} - Sent`
                            : localize("restaurantPage.contact.closed")}
                        </Text>
                      ))
                    ) : (
                      <Text>{localize("restaurantPage.contact.closed")}</Text>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {(email || phone) && (
            <div className={styles.info}>
              <Text className={styles.label} type="label">
                {localize("restaurantPage.contact.contact")}
              </Text>
              {email && (
                <Text className={styles.row}>
                  {localize("restaurantPage.contact.email")}{" "}
                  <a href={`mailto:${email}`}>{email}</a>
                </Text>
              )}
              {phone && (
                <Text className={styles.row}>
                  {injectLocalize(
                    "restaurantPage.contact.phone",
                    format(phone, "-")
                  )}
                </Text>
              )}
            </div>
          )}
        </div>
        {mapUrl && (
          <div className={styles.mapContainer}>
            <img className={styles.map} src={mapUrl} />
          </div>
        )}
      </div>
    </div>
  );
}
