import Masonry from "react-masonry-css";

import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./foodMenu.module.css";
import FoodSection from "./FoodSection/FoodSection";

export default function FoodMenu({ items, name }) {
  const breakpoints = {
    800: 1,
    default: 2,
  };

  if (!items || items?.length === 0) {
    return null;
  }

  return (
    <div className={styles.container} id="meny">
      <div className={styles.header}>
        <Text className={styles.subtitle} color="orange" type="label">
          {name}
        </Text>
        <Text className={styles.title} type="h1">
          {localize("restaurantPage.foodMenu.title")}
        </Text>
      </div>
      <Masonry breakpointCols={breakpoints} className={styles.masonry}>
        {items.map((item, index) => (
          <FoodSection items={item?.items} key={index} name={item?.name} />
        ))}
      </Masonry>
    </div>
  );
}
