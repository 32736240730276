import "./styles/index.css";
import "./styles/vars.css";

import * as ReactDOM from "react-dom/client";

import Root from "./components/Root/Root";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
