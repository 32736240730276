import { useMemo } from "react";

import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./about.module.css";

export default function About({ description, name }) {
  const rows = useMemo(
    () => description?.split("\n")?.filter((item) => !!item),
    [description]
  );

  if (!description) {
    return null;
  }

  return (
    <div className={styles.container} id="om-oss">
      <div className={styles.content}>
        <div className={styles.name}>
          <Text className={styles.label} color="orange" type="label">
            {localize("restaurantPage.about.title")}
          </Text>
          <Text type="h1">{name}</Text>
        </div>
        <div className={styles.description}>
          {rows?.map((row, index) => (
            <Text className={styles.row} color="grey" html={row} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
