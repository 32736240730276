import PropTypes from "prop-types";

import { localize } from "../../../util/localization";
import Text from "../Text/Text";
import styles from "./errorInfo.module.css";

export default function ErrorInfo({ info }) {
  return (
    <div className={styles.container}>
      <Text type="h3">{localize("components.errorInfo.title")}</Text>
      {!!info && <Text className={styles.info}>{info}</Text>}
    </div>
  );
}

ErrorInfo.propTypes = {
  info: PropTypes.string,
};

ErrorInfo.defaultProps = {
  info: "",
};
