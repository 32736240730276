import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useEffect, useMemo } from "react";
import ReactSlick from "react-slick";

import { getImageUrl } from "../../../util/image";
import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./gallery.module.css";

export default function Gallery({ images }) {
  const items = useMemo(() => {
    return images?.map((item) => getImageUrl(item, 600, 400, "fit")) || [];
  }, [images]);

  useEffect(() => {
    // Preload all images once URL has been generated.
    items?.forEach((item) => {
      const img = new window.Image();
      img.src = item;
    });
  }, [items]);

  if (!items || items?.length === 0) {
    return null;
  }

  return (
    <div className={styles.container} id="galleri">
      <Text className={styles.subtitle} color="orange" type="label">
        {localize("restaurantPage.gallery.subtitle")}
      </Text>
      <Text className={styles.title} type="h1">
        {localize("restaurantPage.gallery.title")}
      </Text>
      <ReactSlick
        adaptiveHeight={true}
        arrows={false}
        centerMode
        centerPadding="0px"
        items={items}
        variableWidth={true}
      >
        {items?.map((item, index) => (
          <div className={styles.image} key={index}>
            <img src={item} />
          </div>
        ))}
      </ReactSlick>
    </div>
  );
}
