// API
export const apiUrl = "https://api.bokabord.se";
export const devApiUrl = "http://localhost:8010/proxy";

// Images
export const imageUrl = "//res.cloudinary.com/foodfriends/image";
export const staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";

export const widgetUrl = "//app.waiteraid.com/reservation";
export const googleKey = "AIzaSyDbOqSpCsxpS0eizPGhYJ60ef133VdwGU4";
