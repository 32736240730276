import { useCallback, useContext, useEffect } from "react";

import { widgetUrl } from "../../constants/config";
import { AppContext } from "../../context/AppContext";
import { Modal } from "../shared";
import styles from "./bookingModal.module.css";

export default function BookingModal() {
  const appContext = useContext(AppContext);

  const onRequestClose = useCallback(() => {
    appContext.onHideWidget();
  }, [appContext]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === "closeWaiteraidFrame") {
        onRequestClose();
      }
    };
    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onRequestClose]);

  const getUrl = () => {
    const params = { app_type: "bokabord", hash: appContext?.hash };
    const qs = Object.entries(params)
      .map(([key, value]) => [key, value].join("="))
      .join("&");
    const url = `${widgetUrl}/?${qs}`;
    return url;
  };

  // Only show widget if we have either a restaurant's hash or a custom request.
  if (!appContext?.hash) {
    return null;
  }

  return (
    <Modal onRequestClose={onRequestClose}>
      <div className={styles.iframe}>
        <iframe
          frameBorder={0}
          height="100%"
          id="bokabordStartAppWA"
          scrolling="auto"
          src={getUrl()}
          title="Boka bord"
          width="100%"
        />
      </div>
    </Modal>
  );
}
