import { createContext, useReducer } from "react";

const AppContext = createContext();

const initialState = {
  hash: null,
};

const actions = {
  clearHash: "CLEAR_HASH",
  setHash: "SET_HASH",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setHash: {
      return {
        ...state,
        hash: action.payload,
      };
    }
    case actions.clearHash: {
      return {
        ...state,
        hash: null,
      };
    }
    default:
      return state;
  }
};

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onShowWidget = (params) =>
    dispatch({ payload: params, type: actions.setHash });
  const onHideWidget = () => dispatch({ type: actions.clearHash });
  return (
    <AppContext.Provider
      value={{
        hash: state?.hash,
        onHideWidget,
        onShowWidget,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppContextProvider };
