import classNames from "classnames";
import { useContext, useState } from "react";

import { AppContext } from "../../../context/AppContext";
import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./mobileMenu.module.css";

export default function MobileMenu({ hash, links, onClick }) {
  const appContext = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const onBookClick = () => {
    appContext.onShowWidget(hash);
  };

  const onLinkClick = (item) => {
    onClick(item?.link);
    setOpen(false);
  };

  return (
    <div>
      <div className={styles.menuButton} onClick={() => setOpen(!open)}>
        <svg
          color="#fff"
          fill="currentColor"
          height="32"
          stroke="currentColor"
          strokeWidth="0"
          style={{ color: "white" }}
          viewBox="0 0 512 512"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M432 176H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16zM432 272H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16zM432 368H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16z"></path>
        </svg>
      </div>
      <div className={classNames([styles.menu, open && styles.opened])}>
        {links?.map((item) => (
          <a key={item?.link} onClick={() => onLinkClick(item)}>
            <Text color="orange" type="label">
              {item?.label}
            </Text>
          </a>
        ))}
        <a onClick={onBookClick}>
          <Text color="orange" type="label">
            {localize("restaurantPage.links.book")}
          </Text>
        </a>
      </div>
    </div>
  );
}
