import { useEffect, useState } from "react";

import domains from "../../constants/domains";
import { injectLocalize } from "../../util/localization";
import RestaurantPage from "../RestaurantPage/RestaurantPage";
import { ErrorInfo } from "../shared";

export default function DomainMapping() {
  const [host, setHost] = useState();

  useEffect(() => {
    // Remove "www", "http" and "https" from URL.
    const host = window?.location?.host?.replace(/^(https?:\/\/)?(www\.)?/, "");
    setHost(host);
  }, []);

  // Get the ID based on the host.
  const id = domains[host];

  if (id) {
    return <RestaurantPage id={id} />;
  }

  return <ErrorInfo info={injectLocalize("domainMapping.error", host)} />;
}
