import { useEffect, useState } from "react";

import { debounce } from "../util/debounce";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    height: undefined,
    isMobile: false,
    width: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        height: window.innerHeight,
        isMobile: window.innerWidth < 800,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", debounce(handleResize, 250));
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
