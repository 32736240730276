import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect } from "react";

import styles from "./modal.module.css";

export default function Modal({ children, className, onRequestClose }) {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.keyCode === 27) {
        if (onRequestClose) {
          onRequestClose();
        }
      }
    };

    // Disable scrolling in background when fullscreen menu is opened.
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onRequestClose]);

  return (
    <div className={classNames([styles.container, className])}>{children}</div>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  onRequestClose: PropTypes.func,
};

Modal.defaultProps = {
  children: null,
  className: null,
  onRequestClose: null,
};
