import classNames from "classnames";
import { useContext } from "react";

import { AppContext } from "../../../context/AppContext";
import { getImageUrl } from "../../../util/image";
import { localize } from "../../../util/localization";
import { Text } from "../../shared";
import styles from "./desktopMenu.module.css";

export default function DesktopMenu({ hash, links, logo, onClick, scrolled }) {
  const appContext = useContext(AppContext);

  const onBookClick = () => {
    appContext.onShowWidget(hash);
  };

  const color = scrolled ? "orange" : "white";

  return (
    <div className={classNames([styles.container, scrolled && styles.sticky])}>
      <div className={styles.links}>
        {links?.map((item) => (
          <a key={item?.link} onClick={() => onClick(item?.link)}>
            <Text color={color} type="label">
              {item?.label}
            </Text>
          </a>
        ))}
      </div>
      <img className={styles.logo} src={getImageUrl(logo, 320, 160, "fit")} />
      <div className={styles.bookContainer}>
        <div className={styles.bookButton} onClick={onBookClick}>
          <Text color={color} type="label">
            {localize("restaurantPage.links.book")}
          </Text>
        </div>
      </div>
    </div>
  );
}
