import colors from "../constants/colors";
import { googleKey, staticMapUrl } from "../constants/config";
import { getMapStyles } from "../constants/map";

export const getMapUrl = ({ height, lat, lng, width, zoom }) => {
  const styles = getMapStyles()
    .map((item) => {
      const feature = item.featureType ? `feature:${item.featureType}` : null;
      const element = item.elementType ? `element:${item.elementType}` : null;
      const stylers = item.stylers
        .map((item) =>
          Object.keys(item).map(
            (key) => `${key}:${item[key]?.toString()?.replace("#", "0x")}`
          )
        )
        .join("|");
      const styles = [feature, element, stylers]
        .filter((item) => !!item)
        .join("|");
      return styles;
    })
    .map((item) => `style=${item}`)
    .join("&");

  const params = [];
  params.push(`key=${googleKey}`);
  params.push(`size=${width}x${height}`);
  if (zoom) {
    params.push(`zoom=${zoom}`);
  }
  params.push(`markers=color:0x${colors?.orange?.substring(1)}|${lat},${lng}`);
  params.push(styles);
  const url = `${staticMapUrl}?${params.join("&")}`;
  return url;
};
