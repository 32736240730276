import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./loader.module.css";

export default function Loader({ className }) {
  return (
    <div className={classNames([styles.container, className])}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.any,
};

Loader.defaultProps = {
  className: null,
};
