import { Text } from "../../../shared";
import FoodItem from "./FoodItem/FoodItem";
import styles from "./foodSection.module.css";

export default function FoodSection({ items, name }) {
  return (
    <div className={styles.container}>
      <Text className={styles.name} type="h3">
        {name}
      </Text>
      <div className={styles.separator} />
      <div className={styles.items}>
        {items?.map((item) => (
          <FoodItem
            description={item?.description}
            key={item?.id}
            name={item?.name}
            price={item?.price}
          />
        ))}
      </div>
    </div>
  );
}
