import { imageUrl } from "../constants/config";

export const getImageUrl = (
  path,
  width = 400,
  height = 200,
  filter = null,
  source = "upload"
) => {
  const settings = [];
  if (filter) {
    settings.push(`c_${filter}`);
  }
  if (width) {
    settings.push(`w_${width}`);
  }
  if (height) {
    settings.push(`h_${height}`);
  }
  const urlSettings = settings.join(",");
  return `${imageUrl}/${source}/${urlSettings}/restaurant/${path}`;
};
