import axios from "axios";

import { apiUrl, devApiUrl } from "../constants/config";

const getUrl = (endpoint) => {
  if (endpoint.includes("http")) {
    return endpoint;
  }
  const prefix = process.env.NODE_ENV === "development" ? devApiUrl : apiUrl;
  return prefix + "/" + endpoint;
};

export const get = (...params) => makeRequest("get", ...params);

export const post = (...params) => makeRequest("post", ...params);

const makeRequest = async (method, endpoint, params, config = {}) => {
  try {
    const url = getUrl(endpoint);
    const response = await axios({ method, params, url, ...config });
    if (response?.data?.status === "ERROR") {
      return {
        error: {
          status: response?.code,
        },
      };
    }
    return response?.data;
  } catch (error) {
    return {
      error: {
        status: error?.response?.status,
      },
    };
  }
};
