import { useEffect, useMemo, useState } from "react";
import { scroller } from "react-scroll";

import { useWindowSize } from "../../hooks/windowSize";
import { injectLocalize, localize } from "../../util/localization";
import { get } from "../../util/server";
import { ErrorInfo, Loader } from "../shared";
import About from "./About/About";
import Contact from "./Contact/Contact";
import DesktopMenu from "./DesktopMenu/DesktopMenu";
import FoodMenu from "./FoodMenu/FoodMenu";
import Gallery from "./Gallery/Gallery";
import Hero from "./Hero/Hero";
import MobileMenu from "./MobileMenu/MobileMenu";

export default function RestaurantPage({ id, slug }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [restaurant, setRestaurant] = useState();
  const [scrolled, setScrolled] = useState(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const handleScrolling = () => {
      setScrolled(window?.pageYOffset > 0);
    };

    window.addEventListener("scroll", handleScrolling);
    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(`restaurants/${id || slug}`);
      if (response.error) {
        setError(true);
      } else {
        setRestaurant(response?.data);
        document.title = response?.data?.name;
      }
      setLoading(false);
    };
    fetchData();
  }, [id, slug]);

  const onLinkClick = (id) => {
    const offsets = isMobile
      ? { galleri: -64, kontakt: -48 }
      : { galleri: -164, kontakt: -164, meny: -100, "om-oss": -100 };
    const offset = offsets[id];
    scroller.scrollTo(id, { duration: 1000, offset, smooth: true });
  };

  const links = useMemo(() => {
    const items = [];
    if (restaurant?.longDescription) {
      items.push({
        label: localize("restaurantPage.links.about"),
        link: "om-oss",
      });
    }
    if (restaurant?.foodMenu?.length > 0) {
      items.push({
        label: localize("restaurantPage.links.menu"),
        link: "meny",
      });
    }
    if (restaurant?.images?.length > 0) {
      items.push({
        label: localize("restaurantPage.links.gallery"),
        link: "galleri",
      });
    }
    // Always add "contact".
    items.push({
      label: localize("restaurantPage.links.contact"),
      link: "kontakt",
    });
    return items;
  }, [restaurant]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorInfo info={injectLocalize("restaurantPage.error", [id || slug])} />
    );
  }

  return (
    <>
      {isMobile ? (
        <MobileMenu
          hash={restaurant?.hash}
          links={links}
          onClick={onLinkClick}
        />
      ) : (
        <DesktopMenu
          hash={restaurant?.hash}
          links={links}
          logo={restaurant?.logo}
          onClick={onLinkClick}
          scrolled={scrolled}
        />
      )}
      <Hero
        hash={restaurant?.hash}
        images={restaurant?.images}
        logo={restaurant?.logo}
        scrolled={scrolled}
      />
      <About
        description={
          restaurant?.websiteDescription || restaurant?.longDescription
        }
        name={restaurant?.name}
      />
      <FoodMenu items={restaurant?.foodMenu} name={restaurant?.name} />
      <Gallery images={restaurant?.images} />
      <Contact
        address={restaurant?.address}
        city={restaurant?.city}
        email={restaurant?.email}
        lat={restaurant?.location?.lat}
        lng={restaurant?.location?.lon}
        name={restaurant?.name}
        openingHours={restaurant?.openingHours}
        phone={restaurant?.phone}
      />
    </>
  );
}
